import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useElasticIndexPrefix } from '@/contexts';
import { LimitType } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerLimitHistory,
  PlayerLimitHistoryVariables,
} from './__generated__/PlayerLimitHistory';
import { queryParams } from './queryParams';

const PLAYER_GAME_ROUNDS_QUERY = gql`
  query PlayerLimitHistory(
    $after: String
    $before: String
    $desc: Boolean
    $first: Int
    $from: OffsetDateTime
    $indexPrefix: String
    $last: Int
    $limitPeriodType: LimitTypeEnum
    $limitStatus: LimitStatusEnum
    $limitType: LimitType
    $orderBy: LimitSearchOrder
    $playerId: ID!
    $to: OffsetDateTime
  ) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
      }
      limits(
        after: $after
        before: $before
        desc: $desc
        first: $first
        from: $from
        indexPrefix: $indexPrefix
        last: $last
        limitPeriodType: $limitPeriodType
        limitStatus: $limitStatus
        limitType: $limitType
        orderBy: $orderBy
        to: $to
      ) {
        edges {
          node {
            limitType
            requestedAt
            availableAt
            confirmedAt
            prevValue
            newValue
            period
            limitStatus
            requestedBy {
              ... on PlayerInitiator {
                player {
                  id
                  firstName
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerLimitHistory(
  playerId: string,
  limitType: LimitType,
) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });
  const { indexPrefix } = useElasticIndexPrefix();
  const [{ data, fetching }, refresh] = useQuery<
    PlayerLimitHistory,
    PlayerLimitHistoryVariables
  >({
    query: PLAYER_GAME_ROUNDS_QUERY,
    variables: {
      playerId,
      limitType,
      indexPrefix,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.limits.pageInfo),
    history: data?.player.limits?.edges?.map((edge) => ({
      currency: data?.player.wallet?.currency,
      ...edge?.node,
    })),
  };
}

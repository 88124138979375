import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { LimitSearchOrder } from '@/globalTypes';
import {
  LimitPeriodTypeParam,
  LimitSearchOrderParam,
  LimitStatusParam,
} from '@/utils/query/params';

export const queryParams = {
  limitPeriodType: LimitPeriodTypeParam,
  limitStatus: LimitStatusParam,
  from: DateTimeParam,
  to: DateTimeParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(LimitSearchOrderParam, LimitSearchOrder.requestedAt),
  before: StringParam,
  after: StringParam,
  first: withDefault(NumberParam, 20)
};
